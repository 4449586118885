import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCodes(ctx, data) {
      return useJwt.getCodes(data).then(response => response);
    },







  },
};
